import React from "react";
import FeatureBox from "../../components/featureBox";

const MostPopularServicesBox = () => {
  return (
    <div className="team_wrapper med_toppadder100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
            <div className="team_heading_wrapper med_bottompadder40">
              <h1 className="med_bottompadder20">
                HealthPrax Software Features
              </h1>
              <img
                src="images/icons/Icon_team.png"
                alt="line"
                className="med_bottompadder20"
              />
              <p>
                The HealthPrax Softwares offers you features to efficiently
                manage your Practice.
              </p>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="ser_slider_wrapper">
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <div className="row">
                    <FeatureBox
                      icon="icons/ehr.svg"
                      watermarkIcon="icon_11"
                      title="Electronic Health Records"
                      description={
                        "Secure and faster patient data entry and a comprehensive digital view of a patient’s health history with regulated access of data between you and your staff"
                      }
                      href="features.html#ehr"
                    />
                    <FeatureBox
                      icon="icons/appointment.svg"
                      watermarkIcon="icon_11"
                      title="Patient Registration and Appointment Scheduling"
                      description={
                        "Smooth management of patient appointments, registration and clinic schedule with our efficient software"
                      }
                      href="features.html#scheduling"
                    />
                    <FeatureBox
                      icon="icons/communication.svg"
                      watermarkIcon="icon_11"
                      title="Patient Communication and Reminders"
                      description={
                        "Software-enabled easy communication with your patients for reminders and value added communication"
                      }
                      href="features.html#registration"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MostPopularServicesBox;
