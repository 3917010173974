import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PreLoader from "./preloader";
import {
  faSignInAlt,
  faEnvelope,
  // faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import HeaderMenu from "./headerMenu";
import FeatureSets from "../const/featureSet";

const Header = () => {
  return (
    <>
      <PreLoader />
      <div className="top_header_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="top_header_add">
                <ul>
                  {/* <li>
                    <i>
                      <FontAwesomeIcon icon={faMapMarker} />
                    </i>
                    <span>Address :</span> HealthPrax, India
                  </li> */}
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </i>
                    <a href="mailto:care@healthprax.com">
                      <span>Email: </span>
                      care@healthprax.com
                    </a>
                  </li>
                </ul>
              </div>
              {FeatureSets.PRICING && (
                <div className="top_login">
                  <ul>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faSignInAlt} />
                      </i>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://devportal.healthprax.com/"
                      >
                        Log In / Sign Up
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <HeaderMenu />
    </>
  );
};

export default Header;
