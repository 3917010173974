import React from "react";
import BlogBoxEntry from "./blogBoxEntry";

const BlogBox = (props) => {
  return (
    <div className="blog_section med_toppadder100 med_bottompadder100">
      <div className="container">
        <div className="row">
          <div className="blog_category_main_wrapper">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <BlogBoxEntry title="ABC" author="Dr. Yojna Sah Jain" summary="Test"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogBox;
