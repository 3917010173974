import React from "react";
import FeatureBox from "../../components/featureBox";

const FeatureSetBox = () => {
  return (
    <div className="category_wrapper">
      <div className="container">
        <div className="row">
          <FeatureBox
            icon="icons/ehr.svg"
            watermarkIcon="icon_11"
            title="Electronic Health Records"
            description={
              "Secure and faster patient data entry and a comprehensive digital view of a patient’s health history with regulated access of data between you and your staff"
            }
            href="features.html#ehr"
          />
          <FeatureBox
            icon="icons/appointment.svg"
            watermarkIcon="icon_11"
            title="Patient Registration and Appointment Scheduling"
            description={
              "Smooth management of patient appointments, registration and clinic schedule with our efficient software"
            }
            href="features.html#scheduling"
          />
          <FeatureBox
            icon="icons/communication.svg"
            watermarkIcon="icon_11"
            title="Patient Communication and Reminders"
            description={
              "Software-enabled easy communication with your patients for reminders and value added communication"
            }
            href="features.html#registration"
          />
        </div>
      </div>
    </div>
  );
};
export default FeatureSetBox;
