import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
import TermOfServiceDetailsBox from "./details";
import './tos.scss';

const TermsOfServicePageWeb = () => {
  return (
    <div>
      <Header />
      <PageSecondaryHeaderBox title="Terms of Service" />
      <TermOfServiceDetailsBox />
      <FooterBox />
    </div>
  );
};
export default TermsOfServicePageWeb;
