import React, { useState } from "react";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import FeatureSets from "../../const/featureSet";

const VideoBox = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="video_wrapper">
      <div className="video_overlay"></div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div
              className="video_heading_wrapper wow fadeInDown"
              data-wow-delay="0.5s"
            >
              <h1 className="med_bottompadder20">Welcome to HealthPrax</h1>
              <p />
              <h4>
                <span className="hand_cursor" onClick={() => setOpen(true)}>
                  <img src="images/icons/Play-Icon.png" alt="Play" /> play video
                </span>
                <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={isOpen}
                  videoId="_Yj-ttuLUAo"
                  onClose={() => setOpen(false)}
                />
              </h4>
              {FeatureSets.PRICING && (
                <div className="video_btn_wrapper right">
                  <ul>
                    <li>
                      <a className="btn" href="/">
                        Start your 1-Month Free
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoBox;
