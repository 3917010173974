import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

const AboutHpxBox = () => {
  return (
    <div className="about_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="abt_img abt_box">
              <img src="images/page/hpx_about_us.png" alt="img" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 abt_hpx_section">
            <div className="abt_heading_wrapper">
              <h1 className="med_bottompadder20">About HealthPrax</h1>
              <img
                src="images/icons/line.png"
                alt="title"
                className="med_bottompadder20"
              />
            </div>
            <div className="abt_txt">
              <h3>
                We are a digital health enterprise committed to become a trusted
                technology partner for Healthcare professionals
              </h3>
              <h4>
                Our Mission is to help with transformation and digitization of
                small and medium sized healthcare set-ups with our affordable,
                advanced, and best-in-class Practice Management Softwares
              </h4>
              <p className="med_toppadder20">
                Our services include comprehensive practice management, patient
                engagement and appointment management softwares to facilitate
                automation and digitization of your healthcare practice. Our
                softwares aim at making your practice more eco-friendly by going
                paperless
              </p>
              <p>Softwares by HealthPrax aids you with benefits of:</p>
            </div>
            <div className="abt_chk med_toppadder10">
              <div className="content">
                <ul>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </i>
                    <span>Specialty Specific Softwares</span>
                  </li>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </i>
                    <span>Secure data transactions</span>
                  </li>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </i>
                    <span>Reliable Performance</span>
                  </li>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </i>
                    <span>Faster Data Entry</span>
                  </li>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </i>
                    <span>Easy to use software</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutHpxBox;
