import React from "react";

const PageSecondaryHeaderBox = (props) => {
  return (
    <div className="med_tittle_section">
      <div className="med_img_overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="med_tittle_cont_wrapper">
              <div className="med_tittle_cont">
                <h1>{props.title}</h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{props.title}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageSecondaryHeaderBox;
