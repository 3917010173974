import React from "react";

const FeatureExpandCollapseBox = (props) => {
  const descriptionPresent = props.description && props.description.length > 0;
  const className = descriptionPresent ? "collapsed" : "collapsed-no-arrow";
  return (
    <div className="panel panel-default sidebar_panel">
      <div className="panel-heading horn">
        <h4 className="panel-title">
          <a
            className={`panel-collapse ${className}`}
            data-toggle="collapse"
            data-parent="#accordionFifteenLeft"
            href={"#" + props.keyId}
          >
            {props.title}
          </a>
        </h4>
      </div>
      {descriptionPresent && (
        <div
          id={props.keyId}
          className="panel-collapse collapse"
          style={{ height: "0px" }}
          role="tabpanel"
        >
          <div className="panel-body">
            <div className="panel_cont">
              <p>{props.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureExpandCollapseBox;
