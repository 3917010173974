import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
import BlogBox from "./blogBox";

const BlogsPageWeb = () => {
  return (
    <div>
      <Header />
      <PageSecondaryHeaderBox title="Blogs" />
      <BlogBox />
      <FooterBox />
    </div>
  );
};
export default BlogsPageWeb;
