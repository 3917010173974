import React from "react";
import FeatureSets from "../../const/featureSet";

const FirstImageBox = () => {
  return (
    <div className="slider_main_wrapper">
      <div className="cc_slider_img_section">
        <div className="owl-carousel owl-theme">
          <div className="item cc_main_slide1">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                  <div className="cc_slider_cont1_wrapper">
                    <div className="cc_slider_cont1">
                      <div className="medi">
                        <h2
                          className="bigger_line_height"
                          data-animation-in="fadeInDown"
                          data-animation-out="animate-out fadeOutDown"
                        >
                          Advanced Practice Management Softwares
                        </h2>
                        <p
                          data-animation-in="zoomIn"
                          data-animation-out="animate-out zoomIn"
                        >
                          Digitize, transform and manage your medical practice,
                          with our superior, reliable and faster software
                          solutions
                        </p>
                        {FeatureSets.PRICING && (
                          <ul>
                            <li
                              data-animation-in="bounceInLeft"
                              data-animation-out="animate-out bounceOutLeft"
                            >
                              <a href="pricing.html">
                                Start your 1-Month Free Trial
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstImageBox;
