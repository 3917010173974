import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
import MostPopularServicesBox from "./mostPopularServicesBox";
import CommunicationServicesBox from "./schedulingServiceBox";
import EHRServiceBox from "./EHRServiceBox";
import PatientServiceBox from "./patientRegistrationServiceBox";
const FeaturesPageWeb = () => {
  return (
    <div className="features-page">
      <Header />
      <PageSecondaryHeaderBox title="Features" />
      <MostPopularServicesBox />
      <EHRServiceBox />
      <CommunicationServicesBox />
      <PatientServiceBox />
      <FooterBox />
    </div>
  );
};
export default FeaturesPageWeb;
