import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
const FeatureBox = (props) => {
  const hrefPresent = props.href && props.href.length > 0;
  const watermarkIconPresent =
    props.watermarkIcon && props.watermarkIcon.length > 0;
  const topBoxClass = props.topBoxClass || "";
  return (
    <div className={`col-lg-4 col-md-4 col-sm-12 col-xs-12 ${topBoxClass}`}>
      <div className="cat_about">
        <div className="icon_wrapper">
          <img
            src={`images/${props.icon}`}
            alt="img"
            className="img-responsive"
          />
        </div>
        <div className="cat_img">
          {watermarkIconPresent && (
            <img
            src={`images/icons/${props.watermarkIcon}.png`}
              alt="img"
              className="img-responsive"
            />
          )}
        </div>
        <div className="cat_txt">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          {hrefPresent && (
            <a href={props.href}>
              Read more &nbsp;
              <i>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureBox;
