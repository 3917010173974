import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
import ContactFormBox from "./contactFormBox";
import DetailsBox from "./detailsBox";

const ContactPageWeb = () => {
  return (
    <div>
      <Header />
      <PageSecondaryHeaderBox title="Contact" />
      <ContactFormBox />
      <DetailsBox />
      <FooterBox />
    </div>
  );
};
export default ContactPageWeb;
