import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import CompanyMissionBox from "./companyMissionVision";
import GivingBestBox from "./givingBest";
import TopServiceBox from "./topServices";
import OurTeamBox from "./ourTeam";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
const AboutUsPageWeb = () => {
  return (
    <div>
      <Header />
      <PageSecondaryHeaderBox title="About Us"/>
      <CompanyMissionBox />
      <GivingBestBox />
      <TopServiceBox />
      <OurTeamBox />
      <FooterBox />
    </div>
  );
};
export default AboutUsPageWeb;
