import React from "react";
import TeamAdvisoryBox from "../../components/teamAdvisoryBox";

const KnowAdvisorsBox = () => {
  return (
    <div className="testimonial_wrapper med_toppadder30">
      <div className="test_overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="test_heading_wrapper">
              <h1 className="med_bottompadder20">KNOW OUR ADVISORS</h1>
              <img src="images/icons/line.png" alt="title" />
              <h3 className="med_bottompadder10 med_toppadder10 text_justify">
                Meet our Advisory counsel. Their valuable insights and feedback
                have been backbone to our seamless product development process.
                Their incredible support motivates each and everyone here at
                HealthPrax
              </h3>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="test_slider_wrapper">
              <div className="owl-carousel owl-theme">
                <TeamAdvisoryBox
                  img="dr_jhamb.png"
                  title="Dr. DK Jhamb: (Director of Cardiology, Paras Hospitals, Gurugram, India)"
                  description='Dr. Jhamb is Post doctorate in Cardiology from AIIMS,
                Delhi. He has practiced clinical and interventional
                cardiology for 30 years and done more than 20000 PTCS
                and 1000 device implants. Due to his deep commitment
                towards imparting education, he is relentlessly working
                for skill development of doctors and paramedics through
                his "Pavitra" paramedical institute of Health sciences.
                With a futuristic approach and tech inclination, Dr.
                Jhamb has levied his trust in Healthprax and is
                continuously supporting us with his medical expertise
                and business acumen to help us achieve our vision'
                />
                <TeamAdvisoryBox
                  img="kk.jpg"
                  title="Kshitij Kumar: (Chief Data Officer at Farfetch
                  (NYSE:FTCH)"
                  description="With several decades of leadership experience in the
                  domain of Artificial intelligence, Machine learning and
                  Blockchain Kshitij Kumar is a key Advisory board member
                  at HealthPrax. He is guiding and enabling us with key
                  strategic and technological know-hows to develop and
                  deliver innovative, efficient technology solutions for
                  advancement in healthcare management"
                />
                <TeamAdvisoryBox
                  img="dr_ranjit.png"
                  title="Dr. Ranjit Singh 
                  (Vice Chancellor, Shobhit University, Gangoh, and Director, Adarsh Vijendra Institute of Pharmaceutical Sciences)"
                  description="Prof. Ranjit Singh is recipient of several prestigious awards, 
                  Dr. Ranjit is an eminent Academician who firmly believes in the Digital India Mission and hence shows huge confidence in HealthPrax for betterment of Indian healthcare system. 
                  With more than 30 years of vast experience in Academia and pharmaceutical industry Dr Ranjit is our Strategic mentor; guide; and industry collaborator"
                />
                <TeamAdvisoryBox
                  img="dr_siddhartha.jpg"
                  title="Prof Dr Siddharth Gupta: (MDS, MBA, FMS University of Delhi)"
                  description="Dr Siddharth Gupta is an Oral Oncologist & Maxillofacial Imagiologist with almost 2 decades of experience in medical and corporate healthcare domain. Dr Gupta is widely published, most sought renowned speaker in India & abroad, and is on the Board of ATINER (Athens Institute for Education & Research in Health care), Senior Health care advisor for COVID -19 Anti Corona Task Force and certified Strategic Thinker from IIM Indore"
                />
                <TeamAdvisoryBox
                  img="dr_madan.jpg"
                  title="Dr. Madan Thangavelu: (Formerly at the University of Cambridge (UK))"
                  description="Dr. Madan Thangavelu, is a Genome Biologist at Trans-disciplinary University, Bangalore, India and was formerly associated with the Department of Oncology, & Medical research council, Laboratory of Molecular Biology at the University of Cambridge (UK). Dr. Madan is also General Secretary and Research Director at European Ayurveda Association, Germany; and President International, at Ayush Valley Foundation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KnowAdvisorsBox;
