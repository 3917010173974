import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IndexPageWeb from "./pages/index/index";
import AboutUsPageWeb from "./pages/aboutUs/index";
import FeaturesPageWeb from "./pages/features/index";
import BlogsPageWeb from "./pages/blogs/index";
import PricingPageWeb from "./pages/pricing/index";
import ContactPageWeb from "./pages/contact/index";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import TermsOfServicePageWeb from "./pages/termsOfService";

library.add(faAngleLeft, faAngleRight, fab);

const GAPageViewTrigger = () => {
  if (!window.gtag) return;
  const trackingId = "G-J29QPCP1Z7";
  const pageLocation = `${document.location.href}`;
  window.gtag("config", trackingId, { page_path: pageLocation });
};

window.onpopstate = function (event) {
  GAPageViewTrigger();
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/index.html" element={<IndexPageWeb />}></Route>
        <Route path="/about_us.html" element={<AboutUsPageWeb />}></Route>
        <Route path="/features.html" element={<FeaturesPageWeb />}></Route>
        <Route path="/blog_single.html" element={<BlogsPageWeb />}></Route>
        <Route path="/pricing.html" element={<PricingPageWeb />}></Route>
        <Route path="/tos.html" element={<TermsOfServicePageWeb />}></Route>
        <Route path="/contact_us.html" element={<ContactPageWeb />}></Route>
        <Route path="/" element={<IndexPageWeb />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
