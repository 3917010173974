import React from "react";

const PricingPlansBox = () => {
  return (
    <div className="pricing_section med_toppadder100 med_bottompadder100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
            <div className="team_heading_wrapper med_bottompadder50">
              <h1 className="med_bottompadder20">Pricing Table</h1>
              <img
                src="images/icons/Icon_team.png"
                alt="line"
                className="med_bottompadder20"
              />
              <p>
                Proin gravida nibh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibendum auctor, nisi elit consequat
                ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet
                nibh vulputate.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="pricing_box1_wrapper">
              <div className="box1_heading_wrapper">
                <h1>BASIC plan</h1>
              </div>
              <div className="price_box1_wrapper">
                <div className="price_box1">
                  <h1>
                    <sub>$</sub>
                    <span>46</span>
                  </h1>
                  <p>/ per Month</p>
                </div>
              </div>
              <div className="pricing_cont_wrapper">
                <h1>features</h1>
                <div className="pricing_cont">
                  <ul>
                    <li>3 departments</li>
                    <li>20 test</li>
                    <li>16 emergency</li>
                    <li>no medical assisdance</li>
                    <li>no home visiting</li>
                    <li>20 consultional</li>
                  </ul>
                </div>
              </div>
              <div className="pricing_btn_wrapper">
                <div className="pricing_btn">
                  <ul>
                    <li>
                      <a href="/">order now</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="pricing_box1_wrapper">
              <div className="box1_heading_wrapper">
                <h1>BASIC plan</h1>
              </div>
              <div className="price_box1_wrapper">
                <div className="price_box1">
                  <h1>
                    <sub>$</sub>
                    <span>46</span>
                  </h1>
                  <p>/ per Month</p>
                </div>
              </div>
              <div className="pricing_cont_wrapper">
                <h1>features</h1>
                <div className="pricing_cont">
                  <ul>
                    <li>3 departments</li>
                    <li>20 test</li>
                    <li>16 emergency</li>
                    <li>no medical assisdance</li>
                    <li>no home visiting</li>
                    <li>20 consultional</li>
                  </ul>
                </div>
              </div>
              <div className="pricing_btn_wrapper">
                <div className="pricing_btn">
                  <ul>
                    <li>
                      <a href="/">order now</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="pricing_box1_wrapper">
              <div className="box1_heading_wrapper">
                <h1>BASIC plan</h1>
              </div>
              <div className="price_box1_wrapper">
                <div className="price_box1">
                  <h1>
                    <sub>$</sub>
                    <span>46</span>
                  </h1>
                  <p>/ per Month</p>
                </div>
              </div>
              <div className="pricing_cont_wrapper">
                <h1>features</h1>
                <div className="pricing_cont">
                  <ul>
                    <li>3 departments</li>
                    <li>20 test</li>
                    <li>16 emergency</li>
                    <li>no medical assisdance</li>
                    <li>no home visiting</li>
                    <li>20 consultional</li>
                  </ul>
                </div>
              </div>
              <div className="pricing_btn_wrapper">
                <div className="pricing_btn">
                  <ul>
                    <li>
                      <a href="/">order now</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 med_toppadder70">
            <div className="choose_heading_wrapper additional_info">
              <h1 className="med_bottompadder20">Additional information:</h1>
              <img
                src="images/icons/line.png"
                alt="line"
                className="med_bottompadder20"
              />
              <p>
                Proin grdavida nihhbh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibenduauctor, nisi elit congghsequat
                ipsum,quat ipsum, nec sagittis sem nibh id elit. Duis sed odin
                nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
                vulputate.
                <br /> Proin grdavida nihhbh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibenduauctor, nisi elit congghsequat
                ipsum, nec sagittis sem nibh id elit. Duis sed odinisi elit
                congghse quat ipsum, nec sagittis sem nibh id elit. Duis sed
                odin
              </p>
              <p>
                quat ipsum, nec sagittis sem quat ipsum, nec sagittis sem nibh
                id elit. Duis sed odinnibh id elit. Duis sed odinisi elit
                congghsequat ipsum, nquat ipsum, nec sagittis sem nibh id elit.
                Duis sed odinec quat ipsum, nec sagittis sem nibh id elit. Duis
                sed odin sagittis sem nibh id elit. Duis sed odio sit amet nibh
                vulputate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PricingPlansBox;
