import React from "react";
import { useMediaQuery } from "react-responsive";

const EHRServicesBox = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });

  return (
    <div id="ehr" className="ser_abt" style={{ backgroundColor: "#dedede" }}>
      <div className="container">
        <div className="row">
          {isMobile && (
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="ser_abt_img_resp">
                <img
                  src="images/page/service_abt_ehr.jpg"
                  alt="img"
                  className="img-responsive"
                />
              </div>
            </div>
          )}
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 abt_section">
            <div className="abt_txt abt_txt_resp">
              <h3>Electronic Health Records</h3>
              <p className="med_toppadder20">
                The benefits of adopting EHRs are a well-known and
                well-discussed subject. At healthprax, we offer specialized,
                customizable EHR solutions for various therapeutic categories to
                address the unique pain points associated with the efficient
                recording and access to medical records needed for each
                specialty.
              </p>
            </div>
            <div className="abt_chk med_toppadder30">
              <div className="content">
                <ul>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span></span>Helps to capture all patients records easily.
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      A quick learning curve and no disruption to your current
                      ways of working.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps to capture patient’s past medical history.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps to capture vitals, present complaints, medication
                      etc. in preset templates.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Provides print functionality for prescription and other
                      reports.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="ser_abt_img_resp">
                <img
                  src="images/page/service_abt_ehr.jpg"
                  alt="img"
                  className="img-responsive"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EHRServicesBox;
