import React from "react";
import FeatureSets from "../const/featureSet";

const HeaderMenu = () => {
  const onToggleClick = () => {
    window.handleToggleClick();
  };
  return (
    <div className="menu_wrapper header-area hidden-menu-bar stick">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-3 col-md-3 col-sm-2 col-xs-12 wow bounceInDown"
            data-wow-delay="0.6s"
          >
            <div className="header_logo">
              <a href="/" className="hidden-xs">
                <img
                  src="images/logo/logo.png"
                  alt="logo"
                  title="logo"
                  className="img-responsive"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-10 col-xs-12">
            <nav className="navbar hidden-xs">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div
                className="collapse navbar-collapse respose_nav"
                id="bs-example-navbar-collapse-1"
              >
                <ul className="nav navbar-nav" id="nav_filter">
                  <li>
                    <a href="/index.html">home</a>
                  </li>
                  <li>
                    <a href="/about_us.html">about us</a>
                  </li>
                  <li>
                    <a href="/features.html">features</a>
                  </li>
                  {FeatureSets.PRICING && (
                    <li>
                      <a href="/pricing.html">pricing</a>
                    </li>
                  )}
                  <li>
                    {/* <a href="/blog_single.html">blogs</a> */}
                    <a href="https://healthprax.wordpress.com/">blogs</a>
                  </li>
                  <li>
                    <a href="/contact_us.html">contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="rp_mobail_menu_main_wrapper visible-xs">
              <div className="row">
                <div className="col-xs-6">
                  <div className="gc_logo logo_hidn">
                    <h1>
                      <a href="/">HealthPrax</a>
                    </h1>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div id="toggle" onClick={onToggleClick}>
                    <img alt="Toggle" src="images/icons/toggle.svg" />
                  </div>
                </div>
              </div>

              <div id="sidebar">
                <h1>HealthPrax</h1>
                <div id="toggle_close" onClick={onToggleClick}>
                  &times;
                </div>
                <div id="cssmenu" className="wd_single_index_menu">
                  <ul>
                    <li>
                      <a href="/index.html">index</a>
                    </li>
                    <li>
                      <a href="/about_us.html">about us</a>
                    </li>
                    <li>
                      <a href="/features.html">features</a>
                    </li>
                    {FeatureSets.PRICING && (
                      <li>
                        <a href="/pricing.html">pricing</a>
                      </li>
                    )}
                    <li>
                      {/* <a href="/blog_single.html">blogs</a> */}
                      <a href="https://healthprax.wordpress.com/">blogs</a>
                    </li>
                    <li>
                      <a href="/contact_us.html">contact</a>
                    </li>
                    {FeatureSets.PRICING && (
                      <li>
                        <a href="/">Log In / Sign Up</a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
