import React from "react";
import TeamMemberBox from "../../components/teamMemberBox";

const OurTeamBox = () => {
  return (
    <div className="team_wrapper med_toppadder100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
            <div className="team_heading_wrapper med_bottompadder40">
              <h1 className="med_bottompadder20">Our HealthPrax Team</h1>
              <img
                src="images/icons/Icon_team.png"
                alt="line"
                className="med_bottompadder20"
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="team_slider_wrapper">
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <div className="row">
                    <TeamMemberBox
                      name="Dr. Yojna Sah Jain"
                      fb="https://www.facebook.com/Yojna.sah.jain/"
                      linkedIn="https://www.linkedin.com/in/dr-yojna-sah-jain-76157610/"
                      img="dr_yojna_small.png"
                      description={`(Founder & CEO)`}
                    />
                    <TeamMemberBox
                      name="Praful Jain"
                      img="praful.jpg"
                      linkedIn="https://www.linkedin.com/in/prafuljain/"
                      description={`(Advisor: Engineering & Technology)`}
                    />
                    <TeamMemberBox
                      img="shubhi.jpg"
                      name="Shubhi Kotiya"
                      linkedIn="https://www.linkedin.com/in/shubhikotiya/"
                      description={`(Head of Marketing)`}
                    />
                    <TeamMemberBox
                      img="martin.jpg"
                      name="Martin Tschitschke"
                      linkedIn="https://www.linkedin.com/in/martin-tschitschke/"
                      description={`(Sr. Product Manager)`}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <TeamMemberBox
                      img="anamika.jpg"
                      name="Dr. Anamika Datta"
                      linkedIn="https://www.linkedin.com/in/dr-anamika-datta-75311949/"
                      description={`(Consultant: Product Development)`}
                    />
                    <TeamMemberBox
                      name="Rajendra Dhande"
                      img="rajendra.png"
                      linkedIn="https://www.linkedin.com/in/rajendra-dhande-6b1247128/"
                      description={`(Consultant: Product Development)`}
                    />
                    <TeamMemberBox
                      img="promit.jpg"
                      name="Dr. Promit Ray"
                      linkedIn="https://www.linkedin.com/in/promit-ray"
                      description={`(Tech Consultant: Engineering)`}
                    />
                    <TeamMemberBox
                      img="palak.jpg"
                      name="Palak Lakhani"
                      linkedIn="https://www.linkedin.com/in/er-palak-lakhani-a24418113/"
                      description={`(Digital Marketing Manager)`}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <TeamMemberBox
                      img="sumeetg.jpg"
                      name="Sumeet Gawande"
                      linkedIn="https://www.linkedin.com/in/sumeet-gawande-589589/"
                      description={`(Sr. Consultant, Engineering & Technology)`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurTeamBox;
