import React from "react";
import FeatureExpandCollapseBox from "../../components/featureExpandCollapseBox";
import { useMediaQuery } from "react-responsive";

const WhyChooseUsBox = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1100px)",
  });
  return (
    <div className="choose_wrapper med_toppadder100">
      <div className="choose_overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="choose_heading_wrapper">
              <h1 className="med_bottompadder20">Why Choose Us</h1>
              {/* <img
                src="images/icons/line.png"
                alt="title"
                className="med_bottompadder30"
              /> */}
            </div>
            <div className="sidebar_wrapper">
              <div className="accordionFifteen">
                <div
                  className="panel-group"
                  id="accordionFifteenLeft"
                  role="tablist"
                >
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftOne"
                    title="- Softwares customized to your specialty"
                    description={`Increase clinical efficiency with our customizable
EHR templates that fit your specialty, workflow, and
preferences. It's a faster, easier method to collect
relevant patient data - which means you can spend
more time treating patients and less time fiddling
with charts.`}
                  />

                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftTwo"
                    title="- Safe, secure, online software solution"
                    description={`Data privacy and security is at the core of not only
                    our product designs but also our corporate values.
                    To ensure your personal and clinic data is
                    protected, we utilize the highest industry
                    standards.`}
                  />

                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftThree"
                    title="- Simplified user interface"
                    description={`When it comes to utilizing an EHR system on a
                    day-to-day basis, ease of use is a critical aspect
                    to consider. The Healthprax software is simple
                    to learn and use by anyone having basic computer
                    management skills.`}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftFour"
                    title="- No disruption in doctor’s current ways of working"
                    description={`We recognize your hectic schedule. There is no time
                    to alter your present methods of operation. Rather
                    than you altering your ways of functioning, we
                    attempt to change our system to meet your
                    requirements. Our system enables you to pick the
                    work model that best fits your practice when using
                    our EHR and clinic management softwares.`}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftFive"
                    title="- Lightweight and fast software solution"
                    description={`Speed and efficiency are critical components of a software solution's effective adoption. 
                    Healthprax softwares are compact and fast, enhancing your performance efficiency, 
                    reducing the chances of error, and shortening patient wait times.`}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftSix"
                    title="- Turn your clinic into digital clinic in 30 min"
                    description={`We can upgrade your clinic in a few hours' time. Are
                    you ready?`}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftSeven"
                    title="- After sales reliable customer Support"
                    description={`Customer satisfaction is our topmost priority 
                    and in order to serve you best, 
                    we promise you a top quality after sales support.`}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeftEight"
                    title="- Cloud based system"
                    description={`Cloud-based EHR solutions let small- and
                    medium-sized businesses, as well as enterprises,
                    benefit from lower cost, data accessibility, and
                    data security.`}
                  />
                </div>
                {/* <!--end of /.panel-group--> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="med_slider_img">
              <div
                className={isDesktopOrLaptop ? "item move_image_down" : "item"}
              >
                <img
                  src="images/page/chs_slider_img.jpg"
                  className="img-responsive"
                  alt="story_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyChooseUsBox;
