import React from "react";
import ModalVideo from "react-modal-video";
import { useMediaQuery } from "react-responsive";

const CompanyMissionVisionBox = () => {
  const [isOpen, setOpen] = React.useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1100px)",
  });
  return (
    <div className="about_us_wrapper">
      <div className="container">
        <div className="row">
          <div className="about_img">
            <span className="hand_cursor" onClick={() => setOpen(true)}>
              <img
                src="images/page/video_effect.jpg"
                alt="img"
                className={
                  isDesktopOrLaptop ? "img-responsive" : "img-responsive-tablet"
                }
              />
            </span>
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="rYiAmWU9xf8"
              onClose={() => setOpen(false)}
            />
          </div>
          <div className="about_us_text_top_box">
            <div className="abt_heading_wrapper abt_2_heading">
              <h1 className="med_bottompadder20">About HealthPrax</h1>
              <img
                src="images/icons/line.png"
                alt="title"
                className="med_bottompadder20"
              />
            </div>
            <div className="abt_txt">
              <h3>
                We are a digital health enterprise committed to become a trusted
                technology partner for Healthcare professionals
              </h3>
              <p className="med_toppadder20">
                Our services include comprehensive practice management, patient
                engagement and appointment management softwares that would
                facilitate automation and streamlining of your healthcare
                practice. Our softwares also aim to make your practice more
                eco-friendly by going paperless
              </p>
            </div>
            <div className="abt_heading_wrapper abt_2_heading_mn">
              <h2>Our Vision</h2>
            </div>
            <div className="abt_txt">
              <p className="med_toppadder20">
                To be a Trusted and Prominent HealthTech organization in the
                advancement of the Digital Health Domain
              </p>
            </div>

            <div className="abt_heading_wrapper abt_2_heading_mn">
              <h2>Our Mission</h2>
            </div>
            <div className="abt_txt">
              <p className="med_toppadder20">
                To help with transformation and digitization of small and medium
                sized healthcare set-ups with our affordable, advanced, and
                best-in-class Practice Management Softwares
              </p>
            </div>

            <div className="abt_heading_wrapper abt_2_heading_mn">
              <h2>Our Objectives</h2>
            </div>
            <div className="abt_txt">
              <ul className="med_toppadder20">
                <li>
                  To reduce the information gap between patients and healthcare
                  providers by providing specialty-specific digital health
                  software to manage patient health records and enhance
                  patient-centric care
                </li>
                <li>
                  At Healthprax we aim to enable healthcare providers to provide
                  a seamless patient experience through easy appointments and
                  continuous patient communication with the help of our
                  advanced, cost-effective, and easy-to-use software
                </li>
                <li>
                  Our endeavor is that healthcare providers can ensure real-time
                  patient care and patients can access their digitized health
                  data anywhere, anytime
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyMissionVisionBox;
