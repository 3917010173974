import React from "react";
import { useMediaQuery } from "react-responsive";
const SchedulingServicesBox = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  return (
    <div
      id="registration"
      className="ser_abt"
      style={{ backgroundColor: "#dedede" }}
    >
      <div className="container">
        <div className="row">
          {isMobile && (
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="ser_abt_img_resp">
                <img
                  src="images/page/service_abt_appointment.jpg"
                  alt="img"
                  className="img-responsive"
                />
              </div>
            </div>
          )}
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 abt_section">
            <div className="abt_txt abt_txt_resp">
              <h3>Patient Registration and Appointment Scheduling</h3>
              <p className="med_toppadder20">
                Healthprax software provides a clinic schedule management
                service that helps coordinate the schedule of your clinics and
                the doctors who work in your clinics. The clinic schedule and
                doctor’s schedule is managed centrally to allow new patient
                appointments to be made easily and efficiently.
              </p>
            </div>
            <div className="abt_chk med_toppadder30">
              <div className="content">
                <ul>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>Enables Paperless Patient appointment booking.</span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Super-fast patient registration, improving your staff
                      efficiency.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Quick search for existing patients using multiple
                      parameters.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>Helps your staff to manage your calendars. </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Calendar of multiple clinics can be managed from same user
                      account.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>Provides upcoming appointment report.</span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps differential access for you and your staff.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Provides quick view of all appointments for different
                      doctors in your practice.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>Patient Communication and Reminders.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="ser_abt_img_resp">
                <img
                  src="images/page/service_abt_appointment.jpg"
                  alt="img"
                  className="img-responsive"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SchedulingServicesBox;
