import React from "react";
import FeatureBox from "../../components/featureBox";

const TopServicesBox = () => {
  return (
    <div className="top_service_wrapper">
      <div className="top_serv_overlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
              <div
                className="team_heading_wrapper med_bottompadder50 wow fadeInDown"
                data-wow-delay="0.3s"
              >
                <h1 className="med_bottompadder20">HealthPrax Core Values</h1>
                <img
                  src="images/icons/Icon_team.png"
                  alt="line"
                  className="med_bottompadder20"
                />
              </div>
            </div>

            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="Patient Centric"
              description="Patient Centricity lies at the core of not only our product design but all the decisions that we take at Healthprax."
              watermark=""
              href=""
            />
            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="Customer oriented softwares"
              description="It is as basic as it may sound, yet to truly succeed as an organization, customer oriented ideology is at our core."
              href=""
            />
            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="Data Protection and Security"
              description="It is one of the most important core values our softwares are built on.  We implement strict data protection measures to ensure data integrity at every phase of your practice."
              href=""
            />
            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="Access to Quality Healthcare"
              description="Enabling access to affordable, high-quality healthcare to even those living in remote towns and villages lies at the heart of our services."
              href=""
            />
            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="Speed and efficiency"
              description="We ensure that Speed and Efficiency of our softwares do not limit your productivity."
              href=""
            />
            <FeatureBox
              topBoxClass="featureBox"
              icon="icons/icon_star.png"
              title="User friendly and Practical Solutions"
              description="Easy to use, cloud based software which allows access to patient data anytime, anywhere."
              href=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopServicesBox;
