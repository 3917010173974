import React from "react";
import Header from "../../components/header";
import FooterBox from "../../components/footerBox";
import PageSecondaryHeaderBox from "../../components/pageSecondaryHeader";
import PricingPlansBox from "./pricingPlansBox";

const PricingPageWeb = () => {
  return (
    <div>
      <Header />
      <PageSecondaryHeaderBox title="Pricing" />
      <PricingPlansBox />
      <FooterBox />
    </div>
  );
};
export default PricingPageWeb;
