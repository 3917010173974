import React from "react";
import Header from "../../components/header";
import FirstImageBox from "./firstImageBox";
import FeatureSetBox from "./featureSetBox";
import AboutHpxBox from "./aboutHpxBox";
import WhyChooseUsBox from "./whyChooseUsBox";
import VideoBox from "./videoBox";
import FooterBox from "../../components/footerBox";
import KnowAdvisorsBox from "./knowAdvisorsBox";
import ContactBox from "./contactBox";
// import BlogsBox from "./blogsBox";

const IndexPageWeb = () => {
  return (
    <div className="indexPage">
      <Header />
      <FirstImageBox />
      <FeatureSetBox />
      <AboutHpxBox />
      <VideoBox />
      <WhyChooseUsBox />
      <KnowAdvisorsBox />
      {/* <BlogsBox /> */}
      <ContactBox />
      <FooterBox />
    </div>
  );
};
export default IndexPageWeb;
