import React from "react";

const BlogBoxEntry = (props) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="blog_about">
        <div className="blog_img blog_post_img">
          <figure>
            <img src="images/page/blog_bg_1.jpg" alt="img" className="img-responsive" />
          </figure>
        </div>
        <div className="blog_txt">
          <h1>
            <a href={`${props.href}`}>{props.title}</a>
          </h1>
          <div className="blog_txt_info">
            <ul>
              <li>BY {props.author}</li>
              <li>{props.date}</li>
            </ul>
          </div>
          <p>{props.summary}</p>
          <a href={props.href}>
            Read More <i className="fa fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};
export default BlogBoxEntry;
