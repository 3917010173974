import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
const TeamMemberBox = (props) => {
  const isValid = (text) => {
    if (text && text.length > 0) {
      text = text.trim();
      return text.length > 0;
    }
    return false;
  };
  const showFb = isValid(props.fb);
  const showLinkedIn = isValid(props.linkedIn);
  const showTwitter = isValid(props.twitter);
  const showYoutube = isValid(props.youtube);
  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div className="team_about">
        <div className="team_icon_wrapper">
          <img
            src="images/icons/icon_heart.png"
            alt="img"
            className="img-responsive"
          />
        </div>
        <div className="team_img">
          <img
            src={`images/team/${props.img}`}
            alt="img"
            className="img-responsive"
          />
        </div>
        <div className="team_txt">
          <h1>
            <span>{props.name}</span>
          </h1>
          <p>{props.description}</p>
        </div>
        <div className="team_icon_hover">
          <ul>
            {showFb && (
              <li>
                <a rel="noreferrer" target="_blank" href={props.fb}>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
            )}

            {showTwitter && (
              <li>
                <a rel="noreferrer" target="_blank" href={props.twitter}>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
            )}
            {showLinkedIn && (
              <li>
                <a rel="noreferrer" target="_blank" href={props.linkedIn}>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            )}
            {showYoutube && (
              <li>
                <a rel="noreferrer" target="_blank" href={props.youtube}>
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberBox;
