import React from "react";

const CommunicationServicesBox = () => {
  return (
    <div id="scheduling" className="ser_abt">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
            <div className="ser_abt_img_resp">
              <img
                src="images/page/service_abt_communication.jpg"
                alt="img"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 abt_section">
            <div className="abt_txt abt_txt_resp">
              <h3>Patient Communication and Reminders</h3>
              <p className="med_toppadder20">
                Improves medication compliance and reduces no shows by sending
                customized medication reminders, appointment reminders, and
                health tips to your patients. Using automatic messaging via
                WhatsApp and email, we help your patients to remember their appointments and medication schedule thereby increasing
                patient compliance and loyalty. Appointment reminders also reassures the
                patients of their upcoming visit being still valid, making them
                feel secure and takes away the responsibility of having to
                remember their next appointment. Regular appointments attended
                turns into better health care and better quality of life for the
                patient
              </p>
            </div>

            <div className="abt_chk med_toppadder30">
              <div className="content">
                <ul>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps you communicate easily with your patients.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps in announcing any clinic related information to your patients.
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>Helps in sending appointment and medication reminders. </span>
                  </li>
                  <li>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    <span>
                      Helps in sending health tips to your patients improving
                      treatment compliance.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommunicationServicesBox;
