import React from "react";

const TermOfServiceDetailsBox = () => {
  return (
    <div className="tncTopBox">
      <p>
        THIS "TERMS OF SERVICE" (THE "TOS") IS A LEGAL AGREEMENT BETWEEN THE
        INDIVIDUAL OR LEGAL ENTITY OR ASSOCIATION INTENDING TO USE THE SERVICE
        ("YOU" OR "CUSTOMER") AND YPJ TECHNOLOGIES PRIVATE LIMITED, ON BEHALF OF
        ITSELF AND ITS AFFILIATES/GROUP COMPANIES UNDER THE BRAND "HEALTHPRAX"
        (&ldquo;HEALTHPRAX&rdquo;). BY REGISTERING FOR THE SERVICES OR BY USING
        THE SERVICES, YOU REPRESENT, WARRANT, AND AGREE THAT YOU HAVE READ,
        UNDERSTOOD, AND AGREE TO BE BOUND TO THE TERMS OF THE TOS. IF YOU DO NOT
        AGREE TO BE BOUND BY THE TOS, OR YOU DO NOT HAVE AUTHORITY TO BIND
        CUSTOMER TO THE TOS, YOU MAY NOT USE THE SERVICES.
      </p>
      <p>
        As described below, we may modify the TOS at any time and from time to
        time. You can find the most current version of the TOS at Here .
      </p>
      <p>Definitions</p>
      <p>
        "Active User Account" means a user account that is active in the context
        of HEALTHPRAX software, meaning that the User Account is fully
        functional and can be used to authenticate applicable Services.
      </p>
      <p>
        "Data" means the data stored in Your management software using the
        Services.
      </p>
      <p>
        "HEALTHPRAX ", "Us" and "We" YPJ TECHNOLOGIES PRIVATE LIMITED, on behalf
        of itself and its affiliates/group companies under the brand
        "HEALTHPRAX" (&ldquo;HEALTHPRAX&rdquo;), its employees, representatives,
        and contractors.
      </p>
      <p>
        "Service" and "Services" means the HEALTHPRAX downloaded<sup></sup>{" "}
        software, together with any associated tools, products, services, media,
        and other content delivered to You through the Site or otherwise by
        HEALTHPRAX.
      </p>
      <p>"Site" means www.healthprax.com.</p>
      <p>
        "Solution" means the Digitization of Health related Practice management
        platform provided by HEALTHPRAX. You choose to have it delivered to You
        as part of the Services.
      </p>
      <p>
        "User Account" means a user account in the context of HEALTHPRAX's
        software.
      </p>
      <p>
        "Third Party Software" includes any third-party software that may be
        included with the Services.
      </p>
      <p>1. GRANT OF LICENSE</p>
      <p>
        1.1 License. Subject to the TOS, HEALTHPRAX grants You the revocable,
        non-exclusive, non-transferable, and non-sublicensable license to use
        the Services through Your Active User Accounts.
      </p>
      <p>
        1.2 Named Based User Accounts . You shall ensure that the number of Your
        Active User Accounts is equal to or less than the number of User
        Accounts for which You have paid the applicable License Fee. You are
        responsible for ensuring that access to a User Account is not shared.
        Only one individual may authenticate to one User Account. If You are a
        legal entity or association, all persons using Your User Accounts must
        be either Your employees or Your contractors who have agreed to be bound
        by the TOS. Hardware or software you use to pool connections, reroute
        information, or reduce the number of users that directly access or use
        the Software (sometimes referred to as "multiplexing" or "pooling"),
        does not reduce the number of licenses or Active User Accounts you need.
      </p>
      <p>1.2.</p>
      <p>
        Concurrent Authorized User Accounts. You shall ensure that the number of
        Your Active User Accounts is equal to or less than the number of User
        Accounts for which You have paid the applicable License Fee. You are
        responsible for ensuring that License Software is installed on only one
        computer server or one node of a cluster solely for the use of the
        specific number of Concurrent Authorized Users for which You have paid.
      </p>
      <p>
        1.3 Activation Key. HEALTHPRAX s Shall issue to You a Licensed user
        account for accessing the Licensed cloud based Software. You are
        entirely responsible for any and all activities that occur under Your
        account and all charges incurred from use of the copy of the Licensed
        Software assigned by Serial Number to You. (e.g. maintenance, support or
        license or subscription fee charges). The Licensed Software shall be
        deemed accepted upon the delivery of the Activation Key to You by
        HEALTHPRAX or an Authorized Reseller.
      </p>
      <p>
        1.4 Third Party Software. The Services may contain Third Party Software
        that requires notices and/or additional terms and conditions. Such
        required Third Party Software notices and/or additional terms and
        conditions may be requested from HEALTHPRAX and are made a part of and
        incorporated by reference into the TOS. By accepting the TOS, You are
        also accepting the additional terms and conditions, if any, set forth
        therein.
      </p>
      <p>2. Conditions and Limitations</p>
      <p>
        2.1 No Reselling, Time-Sharing, or Sub-Licensing. You shall not license,
        sublicense, sell, resell, rent, lease, transfer, assign, distribute,
        time share or otherwise commercially exploit or make the Services
        available to any third party, other than as expressly permitted by the
        TOS.
      </p>
      <p>
        2.2 No Unlawful Use or Objectionable Content. You shall not use the
        Services in any unlawful manner or in any manner that interferes with or
        disrupts the integrity or performance of the Services and its components
        or infringes on the rights of another party. You shall not modify, adapt
        or hack any protected (encrypted or compiled) parts of the Software, or
        otherwise attempt to gain unauthorized access to those parts or its
        related systems or networks. You undertake not to promote any material
        that is unlawful, threatening, abusive, malicious, defamatory, false,
        materially inaccurate, or otherwise objectionable. You will not
        reproduce, publish, or distribute content in connection with the Service
        that infringes any third party's trademark, copyright, patent, trade
        secret, publicity, privacy, or other personal or proprietary right.
        HEALTHPRAX offers no assurance that Your use of the Service under the
        terms of the TOS will not violate any law or regulation applicable to
        You.
      </p>
      <p>
        2.3 Responsibility for Use. You assume all responsibility for the use of
        the Services, including but not limited to content and media that is
        created, uploaded to, downloaded from, transmitted and edited using the
        Service. You are responsible for any accesses made to the Service.
      </p>
      <p>
        2.4 Transmission of Data. You acknowledge that technical processing and
        transmission of the Services, including Your content, may be transferred
        unencrypted and involve (a) transmissions over various networks; (b)
        changes to conform and adapt to technical requirements of connecting
        networks or devices and (c) transmission to HEALTHPRAX&rsquo;s third
        party vendors and hosting partners to provide the necessary hardware,
        software, networking, storage, and related technology required to
        operate and maintain the Service. You shall not use the Services as a
        proxy between a user and other network data.
      </p>
      <p>
        2.5 Attribution. With respect to any use of the Service, You shall keep
        the attribution and hyperlink to HEALTHPRAX and its website intact
        unless a license (at a cost) is obtained from HEALTHPRAX to do so.
      </p>
      <p>
        2.6 Backups. You agree to maintain all appropriate backup of files and
        data stored on Your HEALTHPRAX authorised account as You see fit
        <sup></sup>. HEALTHPRAX shall not, in any case, be responsible for any
        data loss whether stored on cloud or locally.
      </p>
      <p>
        2.7 Data Recovery. If loss of data occurs due to an error on
        HEALTHPRAX's part, HEALTHPRAX may attempt to recover the data and
        provide data recovery fee for a service. If loss of data occurs due to
        an error on Your part HEALTHPRAX may, at its discretion, offer a data
        recovery service for a fee. However, HEALTHPRAX shall not, in any case,
        be responsible for any data loss whether stored on cloud or locally.
      </p>
      <p></p>
      <p>
        2.8 Securing Your Authentication Details. You will ensure that all
        passwords and login credentials remain secure at all times for You and
        each of Your User Accounts. If in the case that You suspect a security
        violation You also undertake to notify HEALTHPRAX immediately.
      </p>
      <p>
        2.9 Description of Other Rights and Limitations. You shall not (a)
        reverse engineer, decompile, translate, disassemble, or otherwise
        attempt to derive source code, in whole or in parts, or authorize any
        third party to do any of the foregoing (ii) modify, or create derivative
        works based upon the Services, in whole or in part; (iii) distribute
        copies of the Services; (iv) remove any proprietary notices or labels of
        Your content in the Services content; or (v) resell, lease, rent,
        transfer, sublicense, or otherwise transfer rights of the Services.
        HEALTHPRAX reserves any rights in the Services not expressly granted to
        you in this TOS.
      </p>
      <p>
        2.10 Intellectual Property Rights: All copyrights, patents, trade
        secrets, trademarks and any other intellectual property rights in
        respect to the Services (including but not limited to any images,
        photographs, animations, video, audio, music, text, and "applets"), the
        accompanying materials (if any), and any copies of the Software, are
        owned by HEALTHPRAX or its suppliers. Therefore, you must treat it like
        any other material protected by intellectual property laws.
      </p>
      <p>
        2.11 Disrepute. You shall not commit any act likely to result in the
        disrepute or harming of interests of HEALTHPRAX's name or the name of
        its third party suppliers, whether through explicit act or omission.
      </p>
      <p>3. Liabilities, warranties and indemnification</p>
      <p>
        3.1 Disclaimer of Warranties. No Warranties. THE SERVICE, ACCESS TO THE
        SERVICE, THE INFORMATION CONTAINED ON THE SERVICE, AND THE SITE ARE
        PROVIDED &ldquo;AS IS&rdquo;AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY
        WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED
        TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, AND NON-INFRINGEMENT. HEALTHPRAX MAKES NO WARRANTY THAT (i) THE
        SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE
        UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR VIRUS-FREE, (iii) THE
        RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE
        ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES,
        INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
        SERVICE WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SERVICE
        WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU FROM HEALTHPRAX OR THROUGH OR FROM THE SERVICE SHALL
        CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
      </p>
      <p>
        3.2 Unauthorized Access; Lost or Corrupt Data. HEALTHPRAX IS NOT
        RESPONSIBLE FOR UNAUTHORIZED ACCESS TO ANY DATA, FACILITIES, OR
        EQUIPMENT BY ANYONE USING THE SERVICE OR FOR UNAUTHORIZED ACCESS TO OR
        ALTERATION, THEFT, CORRUPTION, LOSS, OR DESTRUCTION OF ANY DATA FILES,
        PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE SERVICE, WHETHER BY
        ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS. YOU ARE
        SOLELY RESPONSIBLE FOR VALIDATING THE ACCURACY OF ALL OUTPUT AND
        REPORTS. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT
        DATA, INCORRECT REPORTS, OR INCORRECT DATA FILES RESULTING FROM
        PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE MALFUNCTION,
        SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY SOFTWARE. HEALTHPRAX IS
        NOT RESPONSIBLE FOR THE CONTENT OF ANY INFORMATION TRANSMITTED OR
        RECEIVED THROUGH HEALTHPRAX&rsquo;S PROVISION OF THE SERVICE. ANY
        MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE
        IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SERVICE OR LOSS OF DATA THAT
        RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. HEALTHPRAX SHALL NOT BE
        HELD RESPONSIBLE FOR DATA HOSTED ON ITS SERVERS AND YOU ARE SOLELY
        RESPONSIBILITY FOR MAINTAINING LOCAL COPIES OF YOUR DATA AND MAINTAINING
        PROPER AND SUFFICIENT INSURANCE IF COVERAGE IS REQUIRED WITH RESPECT TO
        DATA LOSS.
      </p>
      <p>
        3.3 Warranties Required By Applicable Law. IF APPLICABLE LAW REQUIRES
        ANY WARRANTIES WITH RESPECT TO THE SERVICES, ALL SUCH WARRANTIES ARE
        LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE YOU REGISTERED FOR
        THE SERVICES.
      </p>
      <p>
        3.4. LIMITATION OF LIABILITY. REGARDLESS OF WHETHER ANY REMEDY SET FORTH
        HEREIN FAILS OF ITS ESSENTIAL PURPOSE OR OTHERWISE, TO THE EXTENT
        PERMITTED BY THE LAW OF THE JURISDICTION IN WHICH LICENSEE OBTAINED THIS
        LICENSE, LICENSOR, ITS SUPPLIERS AND DISTRIBUTORS WILL NOT BE LIABLE FOR
        ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL DAMAGES
        OF ANY CHARACTER, INCLUDING BUT NOT LIMITED TO DAMAGES FOR COMPUTER
        MALFUNCTION, LOSS OF INFORMATION, LOST PROFITS AND BUSINESS
        INTERRUPTION, AND THE COST TO OBTAIN SUBSTITUTE SERVICE, ARISING IN ANY
        WAY OUT OF THIS AGREEMENT OR THE USE OF (OR INABILITY TO USE) THE
        SERVICES HOWEVER CAUSED AND WHETHER ARISING UNDER A THEORY OF CONTRACT,
        TORT OR ANY OTHER LEGAL THEORY, EVEN IF LICENSOR, ITS SUPPLIERS
        DISTRIBUTOR WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES
        OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL,
        CONSEQUENTIAL OR SPECIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY
        TO LICENSEE. LICENSOR, ITS SUPPLIERS AND DISTRIBUTORS SHALL NOT BE
        LIABLE FOR ANY CLAIMS OF THIRD PARTIES RELATING TO THE SOFTWARE.
      </p>
      <p>
        LICENSOR, ITS SUPPLIERS AND DISTRIBUTORS WOULD NOT PROVIDE THE SERVICE
        TO LICENSEE FREE OF CHARGE IF LICENSEE DID NOT AGREE TO THE "DISCLAIMER
        OF WARRANTIES" AND "LIMITATION OF LIABILITY" PROVISIONS IN THIS
        AGREEMENT
      </p>
      <p>
        3.5 Indemnification. You agree to indemnify, defend, and hold harmless
        HEALTHPRAX and ] other users of HEALTHPRAX services, and
        HEALTHPRAX&rsquo;s and their affiliates, officers, directors, and
        agents, from and against any claim, cost or liability, including
        reasonable attorneys&rsquo; fees, arising out of or relating to: (a)
        Your use of the Service; (b) any content You create, transmit, or
        display while using the Service; (c) any breach by You of any
        representations, warranties, or agreements contained in the TOS; (d) any
        unlicensed use of the Service using Your User Accounts; and (e) Your
        negligent or wilful misconduct.
      </p>
      <p>4. Trademarks and publicity</p>
      <p>
        4.1 Use of Names and Trademarks. While You remain licensed to use the
        Services, You may use HEALTHPRAX's name or logos in order to identify
        Yourself as a customer. You shall not otherwise use HEALTHPRAX's name or
        trademarks, unless written permission is obtained from HEALTHPRAX or
        otherwise set out in the TOS.
      </p>
      <p>
        4.2 Disrepute. You shall not commit any act where the result of which is
        the likely disrepute or harming of interests of HEALTHPRAX's name or the
        name of its third party vendors, whether through explicit act or
        omission.
      </p>
      <p>
        4.3 Promotional Materials and Publicity. You authorize HEALTHPRAX to use
        Your name and trademarks in HEALTHPRAX's promotional materials and for
        publicity purposes. You can opt-out at any time by writing a request to
        HEALTHPRAX.
      </p>
      <p>5. No Third-Party Beneficiaries.</p>
      <p>
        Nothing express or implied in the TOS is intended to confer, nor shall
        confer, upon any person or entity other than the parties and their
        respective successors or assigns any rights, remedies, obligations, or
        liabilities whatsoever.
      </p>
      <p>6. Confidentiality</p>
      <p>
        6.1 Access to and Disclosure of Your Data. You acknowledge and agree
        that HEALTHPRAX may access, use, and store Your Data for the purpose of
        (i) providing the Service and (ii) responding to Your requests for
        technical support and/or providing other services that we have agreed,
        or may in the future agree, to provide. You also agree that HEALTHPRAX
        may collect anonymized usage data from and/or relating to Your Data and
        Your use of the System (&ldquo;Anonymized Usage Data&rdquo;). HEALTHPRAX
        shall maintain reasonable administrative, physical, and technical
        safeguards for protection of the security, confidentiality, and
        integrity of any of Your Data in HEALTHPRAX's possession. HEALTHPRAX
        will not disclose any of Your Data in HEALTHPRAX's possession to a third
        party unless compelled by law or court order or if You consent in
        writing to the disclosure.
      </p>
      <p>
        6.2 Anonymized Usage Data. In consideration of HEALTHPRAX&rsquo;s
        provision of a license to access and use the Service, You hereby
        transfer and assign to HEALTHPRAX all right, title, and interest in and
        to all Anonymized Usage Data that HEALTHPRAX makes from Your data. You
        agree that HEALTHPRAX may use, disclose, market, license, and sell such
        Anonymized Usage Data for any purpose without restriction, and that you
        have no interest in such information, or in the proceeds of any sale,
        license, or other commercialization thereof.
      </p>
      <p>
        6.3 Consent to receive email Your registration to use the Site and/or
        Services constitutes your consent to receive email and mobile text or
        whatsapp etc. communications from HEALTHPRAX, including messages
        regarding customer service issues and other matters. You may opt not to
        receive email correspondence by clicking the "unsubscribe" link found
        within HEALTHPRAX emails and changing your contact preferences. Please
        note you will continue to receive transactional messages related to our
        Services, even if you unsubscribe from marketing emails.
      </p>
      <p>
        6.4 Privacy Policy. The privacy policy is available at ____________. By
        agreeing to the TOS, You accept the terms of HEALTHPRAX's privacy
        policy.
      </p>
      <p>7. Payments, Sales, and Refunds</p>
      <p>7.1 Payment of Fees. You must pay all fees by the due dates.</p>
      <p>
        7.2 Upgrading, Downgrading, or Switching Solution. The amount You pay
        per billing cycle is dependent on the Solution selected and the number
        of User Accounts selected. If You switch to a Solution which costs more
        or increase the number of User Accounts in the middle of a billing
        cycle, HEALTHPRAX will invoice You at that time for the marginally
        increased amount You owe for that billing cycle. If You switch to a
        Solution which costs less or decrease the number of User Accounts in the
        middle of a billing cycle, HEALTHPRAX will not be required to refund the
        amount by which You will have overpaid for that billing cycle, but
        instead will credit that amount towards the subsequent billing cycle(s).
        ] To change your Solution or the number of User Accounts, you must, at
        HEALTHPRAX's sole discretion, enter into a new license agreement for the
        Software on the then-current terms.
      </p>
      <p>
        7.3 Price Changes. HEALTHPRAX reserves the right to change prices at any
        time.
      </p>
      <p>
        7.4 Credit Card Billing. If You choose to pay by credit or debit card
        You authorize HEALTHPRAX to debit Your account subscription/renewal fees
        from Your card. HEALTHPRAX uses a third-party intermediary to manage
        credit card processing and this intermediary is not permitted to store,
        retain, or use Your billing information except to process Your credit
        card information for HEALTHPRAX. HEALTHPRAX may use Your information in
        order to perform fraud screening.
      </p>
      <p>
        7.5 Refunds. HEALTHPRAX has no obligation to issue refunds. HEALTHPRAX
        may offer a trial period that should be used to evaluate and test the
        Services before purchasing. Any refunds will be made entirely at
        HEALTHPRAX's discretion.
      </p>
      <p>
        7.6 Right to Refuse Access. HEALTHPRAX reserves the right to refuse
        anyone access to the Services for any or no reason.
      </p>
      <p>
        7.7 Taxes. Unless otherwise stated, HEALTHPRAX's charges do not include
        any taxes, levies, duties or similar governmental assessments, including
        value-added, sales, use or withholding taxes assessable by any local,
        state, provincial or foreign jurisdiction (collectively "Taxes"). You
        are responsible for paying Taxes except those assessable against
        HEALTHPRAX based on its income. HEALTHPRAX will invoice You for such
        Taxes if HEALTHPRAX believes there is a legal obligation to do so and
        You agree to pay such Taxes if so invoiced.
      </p>
      <p>8. Termination and Modification</p>
      <p>
        8.1 Termination by You. You may terminate Your account at any time.
        Should You wish to terminate Your account with HEALTHPRAX, You are
        solely responsible for ensuring that You properly terminate Your account
        and secure confirmation of account termination from HEALTHPRAX. If You
        terminate Your account before the end of Your current paid-up
        subscription period, Your termination will nevertheless take effect
        immediately and You will receive no refund. You can terminate Your
        account by writing to HEALTHPRAX. HEALTHPRAX will process Your account
        termination within 7 working days of Your notice. You may be charged in
        this period.
      </p>
      <p>
        8.2 Termination by HEALTHPRAX. HEALTHPRAX reserves the right to (a)
        modify or discontinue, temporarily or permanently, the Services (or any
        part thereof) and (b) refuse any and all current and future use of the
        Services, suspend or terminate Your account (any part thereof) or use of
        the Services and remove and discard any of Your content in the Services,
        for any reason, including without limitation if HEALTHPRAX believes that
        You have violated the TOS. HEALTHPRAX will use reasonable efforts to
        contact You directly via email to warn You prior to suspension or
        termination of Your account. HEALTHPRAX shall not be liable to You or
        any third party for any modification, suspension or discontinuation of
        the Service. HEALTHPRAX will not refund any amounts to You if HEALTHPRAX
        terminates Your access to the Services due to Your violation of the TOS.
      </p>
      <p>
        8.3 Effect of Termination. The termination of Your account with
        HEALTHPRAX for any reason results in the immediate termination of Your
        license to use the Services and upon such termination (i) You shall
        immediately cease all use of the Services, (ii) You will lose access to
        all of Your content on our servers and HEALTHPRAX will delete it in the
        normal course of business operations, and (iii) You shall destroy all
        copies, full or partial, of any components of the Services that may have
        been downloaded by you or otherwise transmitted to you electronically or
        otherwise in connection with the leased or subscription Services. Your
        information cannot be recovered once Your account is terminated.
      </p>
      <p>
        8.4 Changes and Amendments. HEALTHPRAX may change the TOS at any time
        and from time to time without prior notice. Ultimately, it is Your
        responsibility to ] obtain and revisit the TOS. Changes to the TOS will
        be effective immediately, and Your continued use of the Service will
        constitute acceptance of those changes. You agree that HEALTHPRAX shall
        not be liable to You or to any third party for any modification of the
        Service and/or the TOS.
      </p>
      <p>
        8.5 Recovery of fees and costs. Should You continue to use the Services
        after notice of termination has been received, You will be liable to pay
        all costs, including reasonable attorney&rsquo;s fees, to enforce
        HEALTHPRAX's revocation of the Service, as well as any damages suffered
        by HEALTHPRAX in the process.
      </p>
      <p>
        8.6 Survival. Articles 3, 4, 5, 6, 7, and 8 of the TOS will survive
        termination of the TOS or Your account.
      </p>
      <p>9. Interpretation</p>
      <p>
        9.1 Assignment. You may not assign Your account with HEALTHPRAX or Your
        rights under the TOS without HEALTHPRAX&rsquo;s prior written consent,
        except in the case of a sale of all or substantially all of Your assets.
        HEALTHPRAX may assign, in whole or in part, its rights, interests, and
        obligations hereunder without limitation.
      </p>
      <p>
        9.2 Headings. Descriptive headings are for convenience only and shall
        not control or affect the meaning or construction of any provision of
        this Agreement.
      </p>
      <p>
        9.3 Enforcement. The failure of HEALTHPRAX to exercise or enforce any
        right or provision of the TOS shall not be a waiver of that right. No
        provision in the TOS shall be deemed waived and no breach excused,
        unless such waiver or consent shall be in writing and signed by the
        party claimed to have waived or consented. Any consent by any party to,
        or waiver of a breach by the other, whether expressed or implied, shall
        not constitute a consent to, waiver of, or excuse for any other
        different or subsequent breach.
      </p>
      <p>
        9.4 Severability. Any provision of the TOS that shall prove to be
        invalid, void, or illegal, shall in no way affect, impair, or invalidate
        any other provision of the TOS, and such other provisions shall remain
        in full force and effect.
      </p>
      <p>
        9.5 Notices. You agree that HEALTHPRAX may provide You with notices,
        including without limitation those regarding changes to the TOS, by
        e-mail, regular mail, or messages or postings through the Services.
      </p>
      <p>
        9.6 Complete Understanding. The TOS, as may be amended from time to time
        as provided for in Section 7.4, constitute the entire agreement between
        You and HEALTHPRAX and govern Your use of the Services, superseding any
        prior agreements between You and HEALTHPRAX for the use of the Services.
        You also may be subject to additional terms and conditions that may
        apply when You use or purchase certain other HEALTHPRAX services,
        affiliate services, third-party content, or third-party software.
      </p>
    </div>
  );
};
export default TermOfServiceDetailsBox;
