import React from "react";

const DetailsBox = () => {
  return (
    <div className="category_wrapper">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="cat_about cont_cat_abt">
              <div className="icon_wrapper dc_icon_section">
                <img
                  src="images/icons/icon_call.png"
                  alt="img"
                  className="img-responsive"
                />
              </div>

              <div className="cat_txt cont_cat_txt">
                <h1>XXXXXXX</h1>
                <p>Mon-Sat 8:30am - 9:30pm</p>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="cat_about cont_cat_abt">
              <div className="icon_wrapper dc_icon_section">
                <img
                  src="images/icons/icon_envelope.png"
                  alt="img"
                  className="img-responsive"
                />
              </div>

              <div className="cat_txt cont_cat_txt cont_last_child">
                <a href="mailto:care@healthprax.com">
                  <h1>care@healthprax.com</h1>
                </a>
                <p>24/7 online help support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsBox;
