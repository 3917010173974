import React from "react";
import FeatureSets from "../const/featureSet";

export const FooterBox = () => {
  const returnToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {FeatureSets.PRICING && (
        <div className="newsletter_wrapper med_toppadder80 med_bottompadder70">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <div
                  className="newsletter_text wow fadeOut"
                  data-wow-delay="0.5s"
                >
                  <h3>
                    Convinced on your first step towards digitizing your
                    practice. We are here to help you!
                  </h3>
                </div>
              </div>
              {FeatureSets.PRICING && (
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <div className="contact_btn_news">
                    <ul>
                      <li>
                        <a href="/">Book Free Trial</a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="footer_wrapper">
        <div className="container">
          <div className="box_1_wrapper">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                  <div className="footer_widget_add">
                    <a href="/">
                      <img
                        src="images/logo/logo_text.svg"
                        className="img-responsive footer_logo_img"
                        alt="footer_logo"
                      />
                    </a>
                  </div>
                  <div className="footer_box_add">
                    <ul>
                      {/* <li>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>Call us : </span>+61 5001444-122
                      </li> */}
                      <li>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <a href="/">
                          <span>Email :</span> care@healthprax.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--footer_1--> */}
          <div className="booking_box_div">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="footer_main_wrapper">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 gallary_response hidden-sm">
                    <div className="footer_heading">
                      <h1 className="med_bottompadder10">Social</h1>
                      <img
                        src="images/icons/line.png"
                        className="img-responsive"
                        alt="img"
                      />
                    </div>
                    <div className="footer_gallary">
                      <div className="row">
                        <ul>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.youtube.com/channel/UCVh6BMv3n-FzoXqHXAMaAtA"
                            >
                              <i
                                className="fa fa-youtube-play"
                                aria-hidden="true"
                              ></i>
                              &nbsp;YouTube
                            </a>
                          </li>
                          <br />
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.linkedin.com/company/healthprax"
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                              &nbsp;LinkedIn
                            </a>
                          </li>
                          <br />
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://twitter.com/healthprax"
                            >
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                              &nbsp;Twitter
                            </a>
                          </li>
                          <br />
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.facebook.com/HealthPrax/"
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                              &nbsp;FaceBook
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 respons_footer_nav hidden-xs">
                    <div className="footer_heading footer_menu">
                      <h1 className="med_bottompadder10">Useful</h1>
                      <img
                        src="images/icons/line.png"
                        className="img-responsive"
                        alt="img"
                      />
                    </div>
                    <div className="footer_ul_wrapper">
                      <ul>
                        <li>
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>{" "}
                          <a href="/">home</a>
                        </li>
                        <li>
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          <a href="about_us.html">about us</a>
                        </li>
                        <li>
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          <a href="features.html">features</a>
                        </li>
                        {FeatureSets.PRICING && (
                          <li>
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                            <a href="pricing.html">pricing</a>
                          </li>
                        )}

                        <li>
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          {/* <a href="blog_single.html">blogs</a> */}
                          <a href="https://healthprax.wordpress.com/">blogs</a>
                        </li>
                        <li>
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          <a href="contact_us.html">contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="footer_botm_wrapper">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="bottom_footer_copy_wrapper">
                      <span>{`Copyright © 2020 - ${new Date().getFullYear()} - YPJ Technologies Private Limited`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="up_wrapper">
            <span id="return-to-top" onClick={returnToTop}>
              <i className="fa fa-arrow-circle-o-up" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterBox;
