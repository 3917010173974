import React from "react";

const ContactFormBox = () => {
  return (
    <div className="contact_us_section med_toppadder100 med_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div className="choose_heading_wrapper med_bottompadder30">
              <h1 className="med_bottompadder20">Contact us</h1>
              <img
                src="images/icons/line.png"
                alt="title"
                className="med_bottompadder20"
              />
              <p>
                Have a question regarding the HealthPrax softwares. Please send
                us your query using the form below. Our Team shall respond to
                you within 1 business working day. We would highly appreciate
                your patience.
              </p>
            </div>
            <div className="row">
              <form name="sentMessage" id="contactForm" noValidate>
                <div className="cont_main_section">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="contact_form1 dc_cont_div">
                      <div className="form-group contact_form1">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Full Name"
                          data-validation-required-message="Please provide your name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="contact_form1 dc_cont_div">
                      <div className="form-group contact_form1">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                          data-valid="email"
                          data-validation-required-message="Please add your email id"
                          data-error="Email should be valid"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="contact_form1 dc_cont_div">
                      <div className="form-group contact_form1">
                        <input
                          type="text"
                          name="contact_no"
                          id="contact_no"
                          placeholder="Phone"
                          className="form-control"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="contact_form1 dc_cont_div">
                      <div className="form-group contact_form1">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                          className="form-control"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="contact_form4 dc_cont_div">
                      <div className="form-group contact_form4">
                        <textarea
                          rows="4"
                          name="message"
                          id="message"
                          placeholder="Message"
                          required="required"
                          data-validation-required-message="Please enter a message"
                          className="form-control"
                        ></textarea>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div id="success"></div>
                    <div className="contact_btn_wrapper med_toppadder30">
                      <button
                        type="submit"
                        className="submitForm"
                        form-type="inquiry"
                      >
                        Send a Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactFormBox;
