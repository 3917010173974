import React from "react";
import FeatureExpandCollapseBox from "../../components/featureExpandCollapseBox";

const GivingBestBox = () => {
  return (
    <div className="abt_service_section med_toppadder20">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
            <div className="team_heading_wrapper med_bottompadder50">
              <h1 className="med_bottompadder20">
                Data Security and Privacy
              </h1>
              <img
                src="images/icons/Icon_team.png"
                alt="line"
                className="med_bottompadder20"
              />
              <p className="data-owner">The owner of your DATA is YOU</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="abt_left_section med_bottompadder20">
              <img
                src="images/page/abt_img_bg.jpg"
                alt="img"
                className="img-responsive"
              />
            </div>
            <div className="abt_txt">
              <p>
                Data Privacy is our Core Value. At HealthPrax we are governed by
                very strict data security and data privacy principles. The data
                fed by you is end-to-end encrypted and is protected. We do not
                have access to your data. HealthPrax would never send marketing
                promotions to patients registered at your clinic with HPX
                softwares. Patient Communications from HPX servers can be
                accessed only by the doctor and the concerned patient .
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="sidebar_wrapper sidebar_right_abt">
              <div className="accordionFifteen">
                <div
                  className="panel-group"
                  id="accordionFifteenLeft"
                  role="tablist"
                >
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeft"
                    title="- Encrypted Data"
                    description={``}
                  />

                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeft"
                    title="- Safety and security of Cloud Data"
                    description={``}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeft"
                    title="- Data NEVER shared with a third party"
                    description={``}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeft"
                    title="- Practices cannot see each other’s data"
                    description={``}
                  />
                  <FeatureExpandCollapseBox
                    keyId="collapseFifteenLeft"
                    title="- Role based Data access"
                    description={``}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GivingBestBox;
