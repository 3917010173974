import React from "react";

export const PreLoader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <img src="images/icons/preloader.gif" id="preloader_image" alt="loader" />
      </div>
    </div>
  );
};

export default PreLoader;
