import React from "react";
const TeamAdvisoryBox = (props) => {
  return (
    <div className="item">
      <div className="t_icon_wrapper">
        <div className="t_client_cont_wrapper2">
          <p>
            {props.description}
          </p>
          <div className="client_img_abt">
            <img
              className="img-circle"
              src={`images/team/${props.img}`}
              alt="img"
              style={{ width: "120px", height: "120px" }}
            />
            <h5>
              {props.title}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAdvisoryBox;
